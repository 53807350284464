<template>
  <div class="steps">
    <div
      class="steps__button steps__button_prev"
      @click="$emit('prevStep')"
      v-if="!hideArrows"
    >
      <div class="steps__button-arrow">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 4.5L3.75 12L11.25 19.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M5.25 12L20.25 12"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
      Назад
    </div>
    <div class="steps__row">
      <div
        class="steps__item"
        :class="{
          steps__item_filled: activeStep > stepNumber,
          steps__item_active: activeStep == stepNumber,
        }"
        v-for="stepNumber in stepsCount"
        :key="stepNumber"
      >
        {{ stepNumber }} / {{ stepsCount }}
      </div>
    </div>
    <div
      class="steps__button steps__button_next"
      :class="{ 'steps__button steps__button_next_disabled': disableNext, 'steps__button steps__button_next_hide': activeStep == 18 }"
      @click="nextStep()"
      v-if="!hideArrows"
    >
      Далее
      <div class="steps__button-arrow">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.75 4.5L20.25 12L12.75 19.5"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M18.75 12L3.75 12"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    stepsCount: {
      type: Number,
      default: 1,
    },
    activeStep: Number,
    hideArrows: Boolean,
    disableNext: Boolean,
  },
  methods: {
    nextStep() {
      if (!this.disableNext) {
        this.$emit("nextStep");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 0 30px;
  }

  &__item {
    font-size: 0;
    width: 30px;
    height: 4px;
    border-radius: 99px;
    background-color: #e1aecb;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    color: #ffffff;

    @media screen and (max-width: 1220px) {
      width: 10px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    &_filled {
      background-color: #830051;
    }

    &_active {
      width: auto;
      height: auto;
      padding: 8px 14px 7px;
      background-color: #830051;
      font-size: 12px;
      display: block;
    }
  }

  &__button {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.3s;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1f1f1f;
    gap: 12px;
    cursor: pointer;
    user-select: none;

    &_next {
      margin-left: auto;

      &_disabled {
        cursor: not-allowed;
        color: rgba(31, 31, 31, 0.7);

        & .steps__button-arrow {
          color: rgba(139, 142, 142, 0.7);
          background-color: rgba(255, 255, 255, 0.7);
        }
        &::before {
          position: absolute;
          opacity: 0;
          transition: 0.3s;
          bottom: calc(100% + 5px);
          right: 0;
          z-index: 3;
          pointer-events: none;
          display: inline-block;
          width: fit-content;
          padding: 8px 16px;
          background-color: #f8dcdc;
          border: 1px solid #cf1517;
          border-radius: 8px;
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #1f1f1f;
          content: "Пройдите тест";
          white-space: nowrap;
        }

        &:hover {
          &:hover {
            color: rgba(31, 31, 31, 0.7);

            & .steps__button-arrow {
              color: rgba(139, 142, 142, 0.7);
            }
          }
          &::before {
            opacity: 1;
          }
        }
      }

      &_hide {
        opacity: 0;
        pointer-events: none;
      }
    }

    &_prev {
      margin-right: auto;
    }

    &:hover {
      color: #830051;

      & .steps__button-arrow {
        color: #830051;
      }
    }

    &-arrow {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8b8e8e;
      transition: 0.3s;
    }
  }
}
</style>