<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="
                  require('@/assets/img/interactive-clinical-case/step6_icon1.svg')
                "
              />
              Дискуссия с экспертом
            </div>
            <div class="icc__block-description">
             В видео обсудим актуальные стратегии лечения пациентов с дислипидемией.
            </div>
          </div>
          <div class="icc__block-video">
            <video
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/8.mp4"
              :poster="
                require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
              "
              controls
            />
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="15"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
export default {
  name: "ICCStep21",
  components: {
    Steps,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>